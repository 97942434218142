import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  AiOutlineSearch,
  AiOutlineUser,
  AiOutlineShopping,
} from "react-icons/ai"; // Import icons
import "./FashNavbar.css";
import FashNavbarLogo from '../../Images/FashHeaderLogo.svg'

function FashNavbar() {
  // const [showModal, setShowModal] = useState(false);

  // const handleMenuClick = () => {
  //   setShowModal(true);
  // };

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  return (
    <Container
      style={{
        height: "100px",
        backgroundColor: "white",
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        zIndex: "1000",
      }}
      fluid
    >
      <Row>
        <Col xs={8}>
        <img src={FashNavbarLogo} alt="FASH" style={{ width: '500px', height: '770px', paddingBottom: '670px', paddingRight: '340px'}} />

        </Col>
        <Col
          xs={4}
          style={{ display: "flex", justifyContent: "end", paddingTop: "35px" }}
        >
          <AiOutlineSearch style={{ fontSize: "25px", marginRight: "50px" }} />
          <AiOutlineUser style={{ fontSize: "25px", marginRight: "50px" }} />
          <AiOutlineShopping
            style={{ fontSize: "25px", marginRight: "40px" }}
          />
          {/* <AiOutlineMenu
            style={{ fontSize: "25px", marginRight: "10px", cursor: "pointer" }}
            onClick={handleMenuClick}
          />
          <span style={{ fontWeight: "bold", fontSize: "15px" }}>MENU</span> */}
        </Col>
      </Row>

      {/* Modal */}
      {/* <Modal
        show={showModal}
        onHide={handleCloseModal}
        animation={false}
        className="modal-right-desktop"
        fullscreen
      >
        <Modal.Header closeButton>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleCloseModal}
          ></button>
        </Modal.Header>
        <Modal.Body style={{ padding: "30px 30px 10px 10px" }}>
          <h6 style={{ fontSize: "18px", padding: "10px 70px", margin: "0" }}>
            New & Featured
          </h6>
          <h6 style={{ fontSize: "18px", padding: "10px 70px", margin: "0" }}>
            Men
          </h6>
          <h6 style={{ fontSize: "18px", padding: "10px 70px", margin: "0" }}>
            Children
          </h6>
          <h6 style={{ fontSize: "18px", padding: "10px 70px", margin: "0" }}>
            Specials
          </h6>
          <h6 style={{ fontSize: "18px", padding: "10px 70px", margin: "0" }}>
            Sale
          </h6>
          <h6 style={{ fontSize: "18px", padding: "10px 70px", margin: "0" }}>
            Decor & Lifestyle
          </h6>

          <div style={{ padding: "30px 70px" }}>
            <a
              href="/decor-lifestyle"
              style={{
                fontSize: "13px",
                padding: "10px 0",
                textDecoration: "underline",
                textDecorationColor: "black",
                color: "black",
                display: "block",
                margin: "0",
              }}
            >
              Decor & Lifestyle
            </a>
            <a
              href="/decor-lifestyle"
              style={{
                fontSize: "13px",
                padding: "10px 0",
                textDecoration: "underline",
                textDecorationColor: "black",
                color: "black",
                display: "block",
                margin: "0",
              }}
            >
              My Order
            </a>
            <a
              href="/decor-lifestyle"
              style={{
                fontSize: "13px",
                padding: "10px 0",
                textDecoration: "underline",
                textDecorationColor: "black",
                color: "black",
                display: "block",
                margin: "0",
              }}
            >
              Contact Us
            </a>
            <a
              href="/decor-lifestyle"
              style={{
                fontSize: "13px",
                padding: "10px 0",
                textDecoration: "underline",
                textDecorationColor: "black",
                color: "black",
                display: "block",
                margin: "0",
              }}
            >
              +234 7000000000
            </a>
          </div>
        </Modal.Body>

      </Modal> */}
    </Container>
  );
}

export default FashNavbar;

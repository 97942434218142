import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FashNavbar from "./Components/Navbar/FashNavbar";
import FashHero from "./Components/Hero/FashHero";
// import FashPage from "./Components/Page/FashPage";
import FashFooter from "./Components/Footer/FashFooter";

function App() {
  return (
    <>
      <FashNavbar />
      <FashHero />
      {/* <FashPage /> */}
      <FashFooter />
    </>
  );
}

export default App;

import React from 'react';
import './FashFooter.css'


const FashFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
       
        <div className="text-center">
          <p className="text-muted">
            <span style={{ color: 'white' }}>© 2024 Fash Attire</span>
            <span style={{ color: 'white' }}> | </span>
            <a href="/piracy-policy" className="footer-link" style={{ color: 'white' }}> Piracy Policy</a>
            <span style={{ color: 'white' }}> | </span>
            <a href="/terms-of-use" className="footer-link" style={{ color: 'white' }}> Terms of Use</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FashFooter;

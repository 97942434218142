
import React, { useState, useEffect } from 'react';
import FashNavbarPhone from './FashNavbarPhone';
import FashNavbarDesktop from './FashNavbarDesktop';

function FashNavbar() {
 
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust breakpoint

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []); 
  

  return (
    <div className="position-relative">
      {isMobile ? <FashNavbarPhone /> : <FashNavbarDesktop />}
    </div>
  );
}

export default FashNavbar;
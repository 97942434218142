import React, { useState, useEffect } from 'react';
import FashPhone from './FashPhone';
import FashDesktop from './FashDesktop';

const FashHero = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust breakpoint

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []); 
  

  return (
    <div className="position-relative">
      {isMobile ? <FashPhone /> : <FashDesktop />}
    </div>
  );
};

export default FashHero;

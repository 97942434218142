import React, { useRef, useState, useEffect } from 'react';
import FashVideo from "../../Videos/Phone.mp4";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import './FashHero.css'; // Import CSS file for styling

const FashPhone = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  };

  useEffect(() => {
    const handleVideoEnd = () => {
      setIsPlaying(false);
    };

    const video = videoRef.current; // Copy videoRef.current to a variable inside the effect
    video.addEventListener('ended', handleVideoEnd);

    return () => {
      video.removeEventListener('ended', handleVideoEnd); // Use the variable in the cleanup function
    };
  }, []);

  return (
<div className="position-relative" style={{ marginTop: '100px', cursor: 'default' }}>
      <video autoPlay loop muted className="w-100" ref={videoRef}>
        <source src={FashVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* Custom play/pause button */}
      <button className="play-pause-btn" onClick={togglePlay}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} style={{ color: 'white', fontSize: '15px' }} />
        <span className="sr-only">Play/Pause</span>
      </button>
      {/* Custom mute button */}
      <button className="mute-btn" onClick={toggleMute}>
        <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} style={{ color: 'white', fontSize: '15px' }} />
        <span className="sr-only">Mute/Unmute</span>
      </button>
    </div>
  );
};

export default FashPhone;

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AiOutlineSearch, AiOutlineUser, AiOutlineShopping } from 'react-icons/ai'; // Import icons
import FashNavbarLogo from '../../Images/FashHeaderLogo.svg'
import './FashNavbar.css';

function FashNavbarPhone() {
  // const [showModal, setShowModal] = useState(false);

  // const handleMenuClick = () => {
  //   setShowModal(true);
  // };

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  return (
    <Container style={{ height: '100px', backgroundColor: 'white', position: 'fixed', top: '0', left: '0', right: '0', zIndex: '1000' }} fluid>
      <Row>
        <Col xs={4}>
        <img src={FashNavbarLogo} alt="FASH" style={{ width: '290px', height: '370px', paddingBottom: '270px', paddingRight: '180px'}} />
        </Col>
        <Col xs={8} style={{ display: 'flex', justifyContent: 'end', paddingTop: '35px' }}>
          <AiOutlineSearch style={{ fontSize: '25px', marginRight: '17px' }} />
          <AiOutlineUser style={{ fontSize: '25px', marginRight: '17px' }} />
          <AiOutlineShopping style={{ fontSize: '25px', marginRight: '17px' }} />
          {/* <AiOutlineMenu style={{ fontSize: '25px', marginRight: '15px', cursor: 'pointer' }} onClick={handleMenuClick} /> */}
        </Col>
      </Row>

      {/* Modal */}
      {/* <Modal show={showModal} onHide={handleCloseModal} animation={false} className="modal-right-phone" fullscreen>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <h6 style={{ fontSize: '24px', padding: '10px', paddingTop: '30px' }}>New & Featured</h6>
        <h6 style={{ fontSize: '24px', padding: '10px' }}>Men</h6>
        <h6 style={{ fontSize: '24px', padding: '10px' }}>Children</h6>
        <h6 style={{ fontSize: '24px', padding: '10px' }}>Specials</h6>
        <h6 style={{ fontSize: '24px', padding: '10px' }}>Sale</h6>
        <h6 style={{ fontSize: '24px', padding: '10px' }}>Decor & Lifestyle</h6>


        
        <div style={{ paddingTop: '30px'}}>
      <a href="/decor-lifestyle" style={{ fontSize: '18px', padding: '10px', textDecoration: 'underline', textDecorationColor: 'black', color: 'black', display: 'block' }}>
        Decor & Lifestyle 
      </a>
      <a href="/decor-lifestyle" style={{ fontSize: '18px', padding: '10px', textDecoration: 'underline', textDecorationColor: 'black', color: 'black', display: 'block' }}>
        My Order
      </a>
      <a href="/decor-lifestyle" style={{ fontSize: '18px', padding: '10px', textDecoration: 'underline', textDecorationColor: 'black', color: 'black', display: 'block' }}>
        Contact Us
      </a>
      <a href="/decor-lifestyle" style={{ fontSize: '18px', padding: '10px', textDecoration: 'underline', textDecorationColor: 'black', color: 'black', display: 'block' }}>
        +234 7000000000
      </a>
    </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );
}

export default FashNavbarPhone;
